import request from "@/utils/requests"

export function getCoupon(data) {
    return request({
        url: '/Client_Coupon/GetDataPage',
        method: 'post',
        data
    })
}


export function getAddress(data) {
    return request({
        url: '/Client_AddressInfo/GetDataPage',
        method: 'post',
        data
    })
}


export function createAddress(data) {
    return request({
        url: '/Client_AddressInfo/AddData',
        method: 'post',
        data
    })
}


export function updateAddress(data) {
    return request({
        url: '/Client_AddressInfo/UpdateData',
        method: 'put',
        data
    })
}


export function deleteAddress(data) {
    return request({
        url: '/Client_AddressInfo/DeleteData',
        method: 'POST',
        data
    })
}

export function computedShipping(data) {
    return request({
        url: '/OrderCalculateFee/FreightTrialCalculation',
        method: 'post',
        data
    })
}

export function calcOptions(data) {
    return request({
        url: '/OrderCalculateFee/GetQuotedShippingMethods',
        method: 'post',
        data
    })
}


export function getPackages(data) {
    return request({
        url: '/Parcel_Info/GetDataPage',
        method: 'post',
        data
    })
}

export function UpdatePackages(data) {
    return request({
        url: '/Parcel_Info/UpdateData',
        method: 'PUT',
        data
    })
}

export function DeletePackages(data) {
    return request({
        url: '/Parcel_Info/DeleteData',
        method: 'POST',
        data
    })
}

export function budgetPrice(data) {
    return request({
        url: '/OrderCalculateFee/AddOrderBudgetFee',
        method: 'POST',
        data
    })
}


export function createOrder(data) {
    return request({
        url: '/Order/CreateOrder',
        method: 'POST',
        data
    })
}


export function getOrderList(data) {
    return request({
        url: '/Order/ClientGetDataPage',
        method: 'POST',
        data
    })
}


export function getOrderDetail(data) {
    return request({
        url: '/Order/ClientGetOrderDetailsDataPage',
        method: 'POST',
        data
    })
}


export function deleteOrder(data) {
    return request({
        url: '/Order/DeleteData',
        method: 'put',
        data
    })
}

export function deliveryOrder(data) {
    return request({
        url: '/Order/OrderReceiveGoods',
        method: 'put',
        data
    })
}


export function getVirtualWareHouse(data) {
    return request({
        url: '/Base_VirtualWareHouse/GetDataPage',
        method: 'post',
        data
    })
}


export function getAgent(data) {
    return request({
        url: '/Base_Agent/GetDataPage',
        method: 'post',
        data
    })
}


export function getAgentList(data) {
    return request({
        url: '/Client_Info/GetClientAgent',
        method: 'post',
        data
    })
}


export function getProblem(data) {
    return request({
        url: '/Base_ProblemLog/GetDataPage',
        method: 'post',
        data
    })
}

export function setDefaultAddress(data) {
    return request({
        url: '/Client_AddressInfo/SetDefaultAddress',
        method: 'post',
        data
    })
}

export function getChannel(data) {
    return request({
        url: '/OrderCalculateFee/GetAccessChannel',
        method: 'post',
        data
    })
}


export function arrivalCreateOrder(data) {
    return request({
        url: '/Order/ArrivalCreateOrder',
        method: 'post',
        data
    })
}